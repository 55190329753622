import React from "react"
import PropTypes from "prop-types"
import { OverlayTrigger, Popover } from "react-bootstrap"
import { FaWindowClose } from "react-icons/fa"
import { withPrefix } from "gatsby"

const INSTEPPopover = ({ children, placement, title, text, icon, show }) => {
  const isTouchDevice = () => {
    if (typeof window !== "undefined") {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
    }
  }
  return (
    <React.Fragment>
      <OverlayTrigger
        placement={placement}
        show={show}
        overlay={
          <Popover>
            {title && (
              <Popover.Title as="h3" className="d-flex align-items-center">
                <div>
                  <img
                    src={withPrefix(
                      `/images/icons/${icon.replace("_white", "")}.svg`
                    )}
                    alt=""
                    width="25"
                    height="25"
                    className="ml-1 mr-2"
                  />
                </div>
                <div className="flex-grow-1">{title}</div>
                {isTouchDevice() ? (
                  <FaWindowClose className="float-right" />
                ) : null}
              </Popover.Title>
            )}
            <Popover.Content>{text}</Popover.Content>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    </React.Fragment>
  )
}

INSTEPPopover.defaultProps = {
  placement: "right",
  title: null,
  icon: null,
}

INSTEPPopover.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  placement: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default INSTEPPopover
